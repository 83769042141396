<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'FAQ Item'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'FAQ Item Edit'"
      :title="'FAQ Item Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
        <div v-if="langList.length" class="form-create__langs">
          <div
            v-for="lang in langList"
            :key="lang.id"
            @click="openLangModal(lang)"
            class="form-create__lang"
          >
            {{ lang.seoName }}
          </div>
        </div>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Question</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'question'"
              :label="'question'"
              :type="'text'"
              :placeholder="'Question'"
              :error-messages="validationErrors['question']"
              isBordered
            />
          </div>
          <p class="form-create__label">Answer</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'answer'"
              :label="'answer'"
              :placeholder="'Answer'"
              :error-messages="validationErrors['answer']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <p class="form-create__label">Sort</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'sort'"
              :label="'sort'"
              :type="'number'"
              :placeholder="'Sort'"
              :error-messages="validationErrors['sort']"
              isBordered
            />
          </div>
          <p class="form-create__label">FAQ Groups</p>
          <div class="input-container checkbox-container checkbox-container_margin">
            <div v-for="item in faqGroups" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.name"
                :id="item.id + ''"
                :set-value="formDataToSend.groups"
              />
            </div>
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <MainModal v-if="langModal" :on-close="closeLangModal" :modal-center="true">
          <div class="lang-modal">
            <div class="lang-modal__title">Translations for {{ chosenLang.name }}</div>
            <form @submit.prevent="handleSubmitLang">
              <p class="form-create__label">Name</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'question'"
                  :label="'question'"
                  :type="'text'"
                  :placeholder="'Question'"
                  isBordered
                />
                <div
                  @click="handleTranslate('question', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>
              <p class="form-create__label">Content</p>
              <div class="input-container">
                <InputTextarea
                  :set-value="formDataToSendLang"
                  :id="'answer'"
                  :label="'answer'"
                  :placeholder="'Answer'"
                  isBordered
                />
                <div
                  @click="handleTranslate('answer', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>
              <div class="form-create__btn">
                <MainButton :type="'submit'">Update</MainButton>
              </div>
            </form>
          </div>
        </MainModal>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import faqApi from "~/api/faq";
import langApi from "~/api/lang";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import MainModal from "~/components/molecules/MainModal.vue";

export default {
  name: "FaqItemEdit",
  metaInfo: {
    title: "FAQ Item Edit",
  },
  data() {
    return {
      formDataToSend: {
        question: "",
        answer: "",
        status: false,
        groups: {},
        sort: null,
      },
      faqGroups: [],
      formDataToSendLang: {
        question: "",
        answer: "",
        id: null,
      },
      langEdit: false,
      langModal: false,
      chosenLang: null,
      faq: null,
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    InputTextarea,
    MainCheckbox,
    MainModal,
  },
  mounted() {
    this.getItem();
    this.getLang();
  },
  computed: {
    ...mapState("subscription", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("lang", ["lang", "langList"]),
    getFirstLink() {
      return ROUTE.FAQ_ITEM_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        question: this.formDataToSend.question,
        answer: this.formDataToSend.answer,
        status: this.formDataToSend.status,
        sort: this.formDataToSend.sort,
      };
      const groups = Object.keys(this.formDataToSend.groups);
      if (groups.length) {
        let groupsSend = [];
        for (let i = 0; groups.length > i; i++) {
          if (this.formDataToSend.groups[groups[i]] == true) {
            groupsSend.push(groups[i]);
          }
        }
        if (groupsSend.length > 0) {
          data.groups = groupsSend;
        }
      }
      this.$store.commit("faq/setLoading", true);
      const url = `/${this.getId}`;
      try {
        faqApi
          .editFaqItem(url, data)
          .then((res) => {
            if (res.success) {
              this.$store.commit("faq/setError", {});
              this.$store.commit("faq/setValidationErrors", {});
              this.handleBackToList();
            }
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("faq/setError", data);
            if (data.errors) {
              this.$store.commit("faq/setValidationErrors", data.errors);
            }
          })
          .finally(() => {
            this.$store.commit("faq/setLoading", false);
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.FAQ_ITEM_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        faqApi.getItemFaqItem(url).then((res) => {
          this.$store.commit("faq/setError", {});
          this.$store.commit("faq/setValidationErrors", {});
          const data = res.data;
          this.faq = res.data;
          this.formDataToSend.question = data.question;
          this.formDataToSend.sort = data.sort;
          this.formDataToSend.status = data.status;
          this.formDataToSend.answer = data.answer;

          const groups = data.groups;
          if (groups != undefined && groups.length > 0) {
            for (let i = 0; groups.length > i; i++) {
              this.$set(this.formDataToSend.groups, groups[i].id, true);
            }
          }
          this.getGroups();
        });
      } catch (error) {
        console.log(error);
      }
    },
    getGroups() {
      try {
        faqApi.getFaqGroups().then((res) => {
          const data = res.data.items;
          this.faqGroups = data;
          this.setGroups(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    setGroups(data) {
      const groups = data;
      for (let i = 0; groups.length > i; i++) {
        if (this.formDataToSend.groups[groups[i].id] == undefined) {
          this.$set(this.formDataToSend.groups, groups[i].id, false);
        }
      }
    },
    getLang() {
      let url = `?page=${this.page}`;
      this.$store.dispatch("lang/getLangList", url);
    },
    closeLangModal() {
      this.chosenLang = null;
      this.langModal = false;
      this.formDataToSendLang.question = "";
      this.formDataToSendLang.answer = "";
      this.langEdit = false;
    },
    openLangModal(lang) {
      this.langModal = true;
      this.chosenLang = lang;

      const translate = this.faq.translation;
      let translateStatic = translate.find((item) => item.langId === this.chosenLang.id);
      if (translateStatic) {
        this.formDataToSendLang.question = translateStatic.question;
        this.formDataToSendLang.answer = translateStatic.answer;
        this.formDataToSendLang.id = translateStatic.id;
        this.langEdit = true;
      }
    },
    handleSubmitLang() {
      this.$store.commit("references/setLoading", true);
      const data = {
        question: this.formDataToSendLang.question,
        answer: this.formDataToSendLang.answer,
        faq_item_id: this.faq.id,
        lang_id: this.chosenLang.id,
      };
      if (this.langEdit) {
        langApi
          .editLangFaq(data, `/${this.formDataToSendLang.id}`)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      } else {
        langApi
          .createLangFaq(data)
          .then(() => {
            this.$store.commit("references/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            console.log(error);
          });
      }
    },
    handleTranslate(el, lang) {
      this.$store.commit("references/setLoading", true);
      const data = {
        text: this.formDataToSend[el],
        language: lang,
      };
      langApi
        .getTranslation(data)
        .then((res) => {
          this.$store.commit("references/setLoading", false);
          this.formDataToSendLang[el] = res.data.response;
        })
        .catch((error) => {
          this.$store.commit("references/setLoading", false);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
